<template lang="pug">
div.wrapper-preview.pa-15
  div(v-if="documentNotFound").fs-14.fw-300.font-default-light.color-grey {{ $t('notFindData') }}
  div(v-else-if="sailorData").w-100.h-100
    Diploma(v-if="typeOfDocument(sailorData?.service_type_document).value === 1" :documentData="sailorData")
    EtiCertificate(v-if="typeOfDocument(sailorData?.service_type_document).value === 2" :documentData="sailorData")
    ServiceBookOrMedical(v-if="[3, 4].includes(typeOfDocument(sailorData?.service_type_document).value)" :documentData="sailorData")
    Qualification(v-if="typeOfDocument(sailorData?.service_type_document).value === 5" :documentData="sailorData")
    QualificationCertificate(v-if="typeOfDocument(sailorData?.service_type_document).value === 6" :documentData="sailorData")
    div.floating-btn-container
      button(@click="downloadDocument(sailorData)" :disabled="isLoad").floating-btn
        v-progress-circular(v-if="isLoad" indeterminate color="#51699E")
        img(v-else src="@/assets/images/download.svg" alt="download-btn")
  div(v-else).fs-14.fw-300.font-default-light.color-grey {{ $t('documentDisplayedHere') }}
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'DocumentPreview',
  components: {
    Diploma: () => import('@/components/documents/Diploma'),
    ServiceBookOrMedical: () => import('@/components/documents/ServiceBookOrMedical'),
    EtiCertificate: () => import('@/components/documents/EtiCertificate'),
    Qualification: () => import('@/components/documents/Qualification'),
    QualificationCertificate: () => import('@/components/documents/QualificationCertificate')
  },
  computed: {
    ...mapState({
      isLoad: state => state.app.isLoad,
      documentNotFound: state => state.app.documentNotFound,
      sailorData:  state => state.app.sailorData
    })
  },
  methods: {
    ...mapActions(['downloadPDF']),
    typeOfDocument (type) {
      console.log('type :>> ', this.sailorData);
      switch (type) {
        case 'qualification':
        case 'certificate_of_competency':
          return { value: 1 }
        case 'diia_certificate_eti': return { value: 2 }
        case 'medical': return { value: 3 }
        case 'service_book': return { value: 4 }
        case 'diploma_confirmation':
        case 'endorsement_of_competency':
          return { value: 5 }
        case 'qualification_certificate':
        case 'ranker_certificate':
          return { value: 6 }
      }
    },

    downloadDocument(data) {
      this.downloadPDF({
        body: { uuid_document: data.verification_number, type_document: data.service_type_document },
        params: {number_document: data.number_document }
      })
    }
  }
}

</script>